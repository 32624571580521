


















































































import Vue from 'vue';
import { Application, Integration,FetchAssignedUsersQueryVariables, AssignedUser, MarketViewEntity } from '@/generated/graphql';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { getActiveProducts, getNameFromApplication } from '@/helpers/dogHelper';
import NiShow from '@nielsen-media/nlsn-vue-ui/lib/components/NiShow.vue';
import ProductsChipGroup from '@/components/integrations/ProductsChipGroup.vue';
import { CoreActions } from '@/store/core/actions.core';
import { CoreGetter } from '@/store/core/getter.core';
import type {ActionMethod } from '@/helpers/typeHelpers'
import { objectWithoutType } from "@/helpers/userHelper";

@Component({
  components: { NiShow, ProductsChipGroup },
  methods: { getNameFromApplication, getActiveProducts, objectWithoutType },
})

export default class ContactCard extends Vue {
  @Prop()
  integration!: Integration;
  @Prop()
  application!: Application;
  @Action(`core/${CoreActions.FETCH_USERS_FROM_HEN}`)
  performFetchUsersFromHen!: ActionMethod<FetchAssignedUsersQueryVariables, unknown>;
  @Getter(`core/${CoreGetter.GET_USERS_FROM_HEN}`)
  public assignedUsersHen!: {user: AssignedUser, entity: MarketViewEntity}[];

  loadingContacts = false;


  async mounted() {
    const data = {
      marketViewEntity: { id: parseInt(this.integration.marketViewEntity?.id || '0') },
    };

    this.loadingContacts = true;
    const payload: FetchAssignedUsersQueryVariables = data;
    await this.performFetchUsersFromHen(payload);
    this.loadingContacts = false;
  }

  mailTo(contact: {email: string}, isMain: boolean) {
    let ccs = '';
    if (!isMain) {
      ccs = [...this.assignedUsersHen, {user: this.integration.assignedUser}].filter(assignment => assignment && (assignment.user?.email !== contact.email)).map(assignment => assignment.user?.email).join(', ');
    }

    const mailUrl = process.env.GMAIL_BASE_URL || 'https://mail.google.com/mail/u/0/'
    window.open(`${mailUrl}?fs=1&to=${contact.email}&cc=${encodeURIComponent(ccs)}&su=${encodeURIComponent("[FOX] Question Regarding Integration '" + this.integration.name + "' (" + this.application.id + ')')}&body=&tf=cm`, '_blank');
  }

  searchOWL(entity: MarketViewEntity) {
    const level = entity.level === 'parent' ? 'parents' : entity.level;
    const owlUrl = process.env.OWL_BASE_URL || 'https://owl.nlsn-eng-ops.com/mv/';

    window.open(`${owlUrl}${level}/${entity.id}`, '_blank');
  }

}
