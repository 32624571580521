import { Application } from '@/generated/graphql';
import i18n from '@/plugins/i18n';

export function getNameFromApplication(a: Application): string {
  if (a.description?.length) return a.description;
  if (a.name?.length) return a.name;
  return i18n.t('applicationNameDescriptionEmpty').toString();
}

export function getActiveProducts(app: Application | null): string[] {
  if (!app) {
    return [];
  }
  if (app.suspendedProducts) {
    const activeProducts = app.products?.filter(item => app.suspendedProducts!.indexOf(item) < 0);
    return activeProducts ? activeProducts : [];
  }
  return app.products ? app.products : [];
}
