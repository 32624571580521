import { Integration } from '@/generated/graphql';
import i18n from '@/plugins/i18n';

export function sortSearch(integrations: Integration[]): { [id: string]: Integration[] } {
  const noParentText = `${i18n.t('search.noKnownParentText')}`;
  const sorted = integrations.reduce(
    (acc: { [k: string]: Integration[] }, cur: Integration) => {
      const mvEntity = cur.marketViewEntity;
      const curParent = mvEntity != null ? mvEntity.parentName : cur.parentName;
      if (curParent && !acc[curParent]) {
        acc[curParent] = [cur];
      } else if (curParent && acc[curParent]) {
        acc[curParent].push(cur);
      } else {
        acc[noParentText].push(cur);
      }

      return acc;
    },
    { [noParentText]: [] }
  );

  if (!sorted[noParentText]?.length) {
    delete sorted[noParentText];
  }

  return Object.keys(sorted) // alphabetical order
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: sorted[key],
      }),
      {}
    );
}

export function productDeprecated(integration: Integration | null, product: string | null | undefined) {
  const stillContained = integration?.products?.filter(p => p === product) || [];
  return stillContained.length == 0;
}
