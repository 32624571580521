





























































































import Vue from 'vue';
import { getActiveProducts } from '@/helpers/dogHelper';
import { objectWithoutType } from '@/helpers/userHelper';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  Application,
  AssignedUser,
  FetchAssignedUsersQueryVariables,
  FetchTagsQuery,
  FetchTagsQueryVariables,
  Integration,
  MarketViewEntity,
  Tag,
  TagKind,
  UpdateIntegrationMutation,
  UpdateIntegrationMutationInput,
  UpdateIntegrationMutationVariables,
} from '@/generated/graphql';

import { ActionMethod, VForm } from '@/helpers/typeHelpers';
import { CoreActions } from '@/store/core/actions.core';
import { CoreGetter } from '@/store/core/getter.core';

export type SelectedReturnType = {
  dcrStreamType: Tag | undefined;
  deviceType: Tag | null;
  assignedUser: { user: AssignedUser | null | undefined };
};
@Component({ components: {}, methods: { getActiveProducts, objectWithoutType } })
export default class UpdateIntegrationDialog extends Vue {
  @Prop()
  integration!: Integration;
  @Prop()
  application!: Application;

  dialog = false;
  formValid = false;
  searchDeviceType = '';
  deviceTypes: Tag[] = [];
  dcrContentTypes: Tag[] = [];
  selected: SelectedReturnType = {
    dcrStreamType: this.integration.tags?.find(t => t.kind === TagKind.DcrStreamType),
    deviceType: this.integration.deviceTypes![0],
    assignedUser: { user: this.integration.assignedUser },
  };

  @Getter(`core/${CoreGetter.GET_USERS_FROM_HEN}`)
  public assignedUsersHen!: { user: AssignedUser; entity: MarketViewEntity }[];

  @Action(`core/${CoreActions.FETCH_USERS_FROM_HEN}`)
  performFetchUsersFromHen!: ActionMethod<FetchAssignedUsersQueryVariables, unknown>;
  @Action(`core/${CoreActions.FETCH_TAGS}`)
  performFetchTags!: ActionMethod<FetchTagsQueryVariables, FetchTagsQuery>;
  @Action(`core/${CoreActions.MUTATION_UPDATE_INTEGRATION}`)
  performUpdateIntegration!: ActionMethod<UpdateIntegrationMutationVariables, UpdateIntegrationMutation>;

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  async mounted() {
    this.deviceTypes = (await this.performFetchTags({ kind: TagKind.DeviceType })).fetchTags;
    this.dcrContentTypes = (await this.performFetchTags({ kind: TagKind.DcrStreamType })).fetchTags;
    const data = {
      marketViewEntity: { id: parseInt(this.integration.marketViewEntity?.id || '0') },
      filterOut: objectWithoutType(this.integration.assignedUser),
    };
    const payload: FetchAssignedUsersQueryVariables = data;
    this.performFetchUsersFromHen(payload);
  }

  async updateIntegration() {
    const payload: UpdateIntegrationMutationInput = {
      integrationId: this.integration.id,
      deviceTypeId: this.selected.deviceType?.id,
      dcrStreamTypeId: this.selected.dcrStreamType?.id,
      assignedUser: objectWithoutType(this.selected.assignedUser?.user),
    };
    await this.performUpdateIntegration(payload)
      .then(() => {
        this.$router.go(0);
        this.dialog = false;
      })
      /* eslint-disable @typescript-eslint/no-explicit-any*/
      .catch((err: any) => console.error(err));

    // @ts-ignore
    /* eslint-disable @typescript-eslint/camelcase */
    this.$gtag.event('update_integration', {
      event_category: 'integration',
      event_label: 'edit integration details',
      value: `${this.integration!.id}`,
    });
  }
}
