



















import { Application, Tag } from '@/generated/graphql';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getActiveProducts } from '@/helpers/dogHelper';

@Component({ components: {}, methods: { getActiveProducts } })
export default class ProductsChipGroup extends Vue {
  @Prop({ default: '' }) app!: Application;
  @Prop({ default: () => '' }) activeProduct!: string;
  @Prop({ default: () => null }) dcrVideoStreamType!: Tag | null;

  get products() {
    return getActiveProducts(this.app);
  }

  hasStreamType(product: string) {
    return product === 'dcr_video' && this.dcrVideoStreamType;
  }

  chipStyle(product: string) {
    if (this.app?.suspendedProducts == null) return 'prod';
    if (this.app.suspendedProducts.includes(product)) return 'prod-suspended';
    return 'prod';
  }
}
