

























































































import { Application, Integration, Tag, TagKind } from '@/generated/graphql';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getActiveProducts, getNameFromApplication } from '@/helpers/dogHelper';
import NiShow from '@nielsen-media/nlsn-vue-ui/lib/components/NiShow.vue';
import ProductsChipGroup from '@/components/integrations/ProductsChipGroup.vue';
import ContactCard from '@/components/integrations/ContactCard.vue';
import { getCssClassFromState } from '@/helpers/integrationHelper';
import UpdateIntegrationDialog from '@/components/integrations/UpdateIntegrationDialog.vue';
import { Actions } from '@nielsen-media/identity-spa';

@Component({
  components: { UpdateIntegrationDialog, NiShow, ProductsChipGroup, ContactCard },
  methods: { getNameFromApplication, getActiveProducts, getCssClassFromState },
})
export default class IntegrationDetails extends Vue {
  isAuthorized = false;
  @Prop()
  integration!: Integration;
  @Prop()
  application!: Application;
  @Prop({ default: () => '' })
  activeProduct!: string;

  colorMap = {
    'not certified': 'planned',
    certified: 'production',
    recertifying: 'recertifying',
  };

  get dcrStreamType(): Tag | undefined {
    return this.integration?.tags?.find(t => t.kind === TagKind.DcrStreamType);
  }

  get superUser(): boolean | undefined {
    return this.isAuthorized;
  }

  async mounted() {
    await this.$identity.authorize('/nlsn/ops/fox/update_integrations', Actions.WRITE).then(res => {
      this.isAuthorized = res['data']['allowed'];
    });
  }
}
