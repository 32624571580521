var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","right":"","transition":"scale-transition","origin":"top left"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.assignedUsersHen.length > 0)?_c('v-chip',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.assignedUsersHen[0].user.name)+" ")]):(_vm.loadingContacts)?_c('v-chip',[_vm._v("loading ...")]):_c('v-chip',_vm._g({},on),[_vm._v(_vm._s(_vm.$t('noContact')))])]}}])},[_c('v-card',{attrs:{"width":"300"}},[_c('v-list',[_c('v-list-item',{staticClass:"v-list theme--dark"},[_c('v-list-item-action',[(_vm.assignedUsersHen.length > 0)?_c('v-icon',[_vm._v("mdi-arrow-right")]):_vm._e()],1),(_vm.assignedUsersHen.length > 0)?_c('v-list-item-content',{on:{"click":function () { return _vm.mailTo(_vm.assignedUsersHen[0].user, true); }}},[_c('v-tooltip',{attrs:{"right":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-title',_vm._g({},on),[_vm._v(_vm._s(_vm.assignedUsersHen[0].user.name))])]}}],null,false,2578323294)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('sendEmail') + _vm.assignedUsersHen[0].user.email)+" ")])]),_c('v-tooltip',{attrs:{"right":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-subtitle',_vm._g({on:{"click":function () { return _vm.searchOWL(_vm.assignedUsersHen[0].entity); }}},on),[_vm._v(_vm._s(_vm.assignedUsersHen[0].entity.name))])]}}],null,false,3401843842)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('openOwl'))+" ")])])],1):_c('v-list-item-content',{on:{"click":function () {}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('noContact')))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.menu = false}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1)],1)],1),_c('v-divider'),_c('v-spacer'),_c('div',{staticClass:"text-center text-h9 pa-2 v-list theme--dark"},[_vm._v(" "+_vm._s(_vm.$t('hierarchyContacts'))+" ")]),_c('v-spacer'),_c('v-list',_vm._l((_vm.assignedUsersHen.slice(1)),function(assignment){return _c('v-list-item',{key:assignment.user.email},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-subdirectory-arrow-right")])],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"right":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-title',_vm._g({on:{"click":function () { return _vm.mailTo(assignment.user, false); }}},on),[_vm._v(_vm._s(assignment.user.name))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('sendEmail') + assignment.user.email)+" ")])]),_c('v-tooltip',{attrs:{"right":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-subtitle',_vm._g({on:{"click":function () { return _vm.searchOWL(assignment.entity); }}},on),[_vm._v(_vm._s(assignment.entity.name))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('openOwl'))+" ")])])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }